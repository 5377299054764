<div class="banner" role="banner">
  <h1 class="text-uppercase">{{ 'tiers.page.title' | translate }}</h1>
</div>
<fgb-account-nav></fgb-account-nav>

<div class="container mt-4">
  <p class="font-size-14" [innerHtml]="'tiers.page.description' | translate | markdownTranslate"></p>

  <ng-container *ngIf="tier$ | async as tier">
    <div class="tier-item-list-container" *ngIf="tier.Tiers?.length > 0">
      <div class="tier-item-list">
        <div class="tier-item" [class.active]="item.TierId <= tier.CurrentTierId" *ngFor="let item of tier.Tiers">
          <div class="image-container">
            <img [src]="item?.Image | contentImage : '7'" alt="{{ item?.Name }}" />
          </div>
          <div class="tier-information">
            <div class="tier-name-wrapper d-flex align-items-center">
              <i class="tier-status material-icons" *ngIf="item.TierId <= tier.CurrentTierId">check_circle</i>
              <div class="tier-name">{{ item?.Name }}</div>
            </div>

            <div *ngIf="item.Name == 'ROOKIE'" class="tier-description">{{ 'tier.rookie.description' | translate }}</div>
            <div *ngIf="item.Name == 'STARTER'" class="tier-description">{{ 'tier.starter.description' | translate }}</div>
            <div *ngIf="item.Name == 'ALL STAR'" class="tier-description">{{ 'tier.allstar.description' | translate }}</div>
            <div *ngIf="item.Name == 'HALL OF FAMER'" class="tier-description">{{ 'tier.famer.description' | translate }}</div>

            <!-- <div class="tier-description">{{ item?.Benefits }}</div> -->
          </div>
        </div>
      </div>
      <div class="progress">
        <span class="progress-bar" [style]="progressBarStyle$ | async"></span>
      </div>
    </div>
  </ng-container>
</div>
