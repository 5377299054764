<div class="banner d-lg-flex d-none">
  <h1>{{ 'events.page.title' | translate }}</h1>
</div>

<div class="container my-3 d-lg-none">
  <fgb-member-card></fgb-member-card>
</div>

<fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>

<div class="container mt-3">
  <fgbcl-marketplace-list
    [isList]="true"
    [showSortBar]="false"
    [marketplaceItemTypes]="[3, 5]"
    [eventItemView]="true"
  ></fgbcl-marketplace-list>
</div>
