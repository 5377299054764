import { BreakpointObserver } from "@angular/cdk/layout"
import { computed, DestroyRef, Directive, inject, OnInit, Signal } from "@angular/core"
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { Barcode, BarcodeQuery, BarcodeService, getLoyaltyBalance,  LoyaltyCustomerTier,  LoyaltyTier,  MemberDetails, MemberQuery } from "@fgb/core"
import { firstValueFrom } from "rxjs";
import { TierService } from './tier.service';
import { TierQuery } from './tier.query';

@Directive()
export class BaseTier implements OnInit{
    private tierService: TierService = inject(TierService);
    private tierQuery: TierQuery = inject(TierQuery);
    private memberQuery: MemberQuery = inject(MemberQuery);
    private barcodeQuery: BarcodeQuery = inject(BarcodeQuery);
    private barcodeService: BarcodeService = inject(BarcodeService);
    private breakpoint: BreakpointObserver = inject(BreakpointObserver);
    private _destroyRef = inject(DestroyRef);

    tier: Signal<LoyaltyCustomerTier | undefined> = toSignal(this.tierQuery.customerTier$);
    currentTier: Signal<LoyaltyTier | undefined> = toSignal(this.tierQuery.currentTier());
    barcode: Signal<Barcode | undefined> = toSignal(this.barcodeQuery.select());
    member: Signal<MemberDetails | undefined> = toSignal(this.memberQuery.selectMemberDetails());
    balance = getLoyaltyBalance();
    lastTier = toSignal(this.tierQuery.lastTier());
    nextTier = toSignal(this.tierQuery.nextTier());
    progress = computed(() => { 
      const tierBalance = this.tier()?.Balance || 0;
      const currentTierMaxPoints = this.currentTier()?.MaximumTierPoints || 0;

      if( tierBalance > currentTierMaxPoints){
        return 100;
      }

      return (tierBalance / currentTierMaxPoints) * 100; 

     });

     progressRanking = computed(() => {
      const currentTierMemberCount = this.tier()?.CurrentTierMemberCount || 0;
      const currentPosition = this.tier()?.CurrentTierPosition || currentTierMemberCount;
      // Where 1 is 100% and 0% is current position same value as current tier member count
      const progress = (1 - ((currentPosition - 1) / (currentTierMemberCount - 1))) * 100;

      return Number.isNaN(progress) || progress < 0 ? 0 : progress;
    });

    ngOnInit(): void {
        this.breakpoint.observe(['(max-width: 991px)']).pipe(
            takeUntilDestroyed(this._destroyRef)
        ).subscribe(result => {
          if(result.matches){
            firstValueFrom(this.barcodeService.fetchBarcode());
          }
        });

        firstValueFrom(this.tierService.fetchTiersWithPositions());
      }
    

}