<div class="mobile-nav-menu bg-dark-green d-flex position-fixed flex-column d-lg-none">
  <!-- NAV BAR -->
  <div class="mobile-nav-bar d-flex justify-content-between">
    <a class="nav-item fw-bold brand p-1" [routerLink]="['/']" routerLinkActive="router-link-active">
      <label id="homeLink" class="accessibility-only">Go To Home Page</label>
      <img alt="" class="home-logo" src="assets/images/club-logo.png" draggable="false" />
    </a>
    <ng-container *notInPopulation="[populations.NonMember]">
      <a class="nav-item" [routerLink]="['/rewards']">
        <span class="icon material-icons material-mobile-nav" role="img" attr.aria-label="{{ 'nav.rewards' | translate }}"
          >redeem</span
        >
      </a>
      <a class="nav-item" [routerLink]="['/rewards/wallet']">
        <span class="icon material-icons material-mobile-nav" role="img" attr.aria-label="{{ 'nav.wallet' | translate }}"
          >account_balance_wallet</span
        >
      </a>
      <!-- <fgb-notification-button [size]="26"></fgb-notification-button> -->
    </ng-container>

    <ng-container *inPopulation="[populations.NonMember]">
      <a class="nav-item" [routerLink]="['/rewards']">
        <span class="icon material-icons material-mobile-nav" role="img" attr.aria-label="{{ 'nav.rewards' | translate }}"
          >redeem</span
        >
      </a>
      <a class="nav-item" [routerLink]="['/challenges']">
        <span class="icon material-icons material-mobile-nav" role="img" attr.aria-label="{{ 'nav.challenges' | translate }}"
          >flag</span
        >
      </a>

      <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.games'">
        <a class="nav-item" [routerLink]="['/games/quiz']">
          <span class="icon material-icons material-mobile-nav" role="img" attr.aria-label="{{ 'nav.games' | translate }}"
            >extension</span
          >
        </a>
      </ng-container>
    </ng-container>

    <button
      attr.aria-label="{{ 'nav.toggle.screenreader' | translate }}"
      class="nav-item fw-bold navbar-toggler m-0 px-2"
      (click)="isCollapsed = !isCollapsed"
    >
      <span class="icon material-icons material-mobile-nav">menu</span>
    </button>
  </div>

  <!-- NAV SCREEN -->
  <div [ngbCollapse]="isCollapsed" class="mobile-nav-menu-dropdown">
    <div class="d-flex flex-column p-1 mobile-nav-menu-content">
      <!-- Close Button -->
      <button
        class="close-button position-absolute border-0"
        attr.aria-label="{{ 'modal.close' | translate }}"
        (click)="isCollapsed = !isCollapsed"
      >
        <span class="icon material-icons-outlined">close</span>
      </button>
      <div class="ms-3">
        <img alt="" class="panathinaikos-logo" src="assets/images/club-logo.png" draggable="false" />
      </div>
      <div class="container mt-3">
        <fgb-member-card [dropDownMemberCard]="true"></fgb-member-card>
      </div>
      <div class="text-center px-2" *ngIf="memberDetails$ | async as memberDetails">
        <div class="switch-account-container" *ngIf="cards$ | async as cards" [ngClass]="{ 'mb-3': cards.length > 1 }">
          <div ngbAccordion #a="ngbAccordion" *ngIf="cards.length > 1" [destroyOnHide]="false" class="mt-3 w-100 mt-lg-0">
            <div ngbAccordionItem id="ngb-panel-accounts">
              <div ngbAccordionHeader>
                <div class="row" (click)="a.toggle('ngb-panel-accounts')">
                  <div class="col-12">
                    <div class="faq-question-text text-primary ps-0 d-flex align-items-center justify-content-center">
                      <span class="icon icon-user-male-pictures text-primary me-1"></span>
                      <span class="earn-question-titles">{{ 'switch.account.dropdown.text' | translate }}</span>
                      <button
                        class="btn py-1 switch-account-btn shadow-none"
                        type="button"
                        ngbPanelToggle
                        (click)="a.toggle('ngb-panel-accounts')"
                        attr.aria-label="{{ 'nav.account.expand.screenreader' | translate }}"
                      >
                        <span class="icon icon-arrow-1-down text-primary fw-bold"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <ng-template ngbPanelContent>
                <div class="row">
                  <div class="content col-12">
                    <div class="card card-body p-0">
                      <div class="font-size-12">
                        <ng-container *ngIf="cards$ | async as cards">
                          <div class="select-account-container">
                            <div *ngFor="let card of cards">
                              <hr class="text-muted w-75 my-0" />
                              <div class="text-secondary my-3">
                                <button
                                  (click)="loginWithCard(card.MemberId)"
                                  class="d-flex align-items-center account-btn text-secondary bg-white py-0 px-2 nav-item-fixed-height"
                                  ngbDropdownItem
                                  attr.aria-label="{{ 'nav.account.expand.login.screenreader' | translate }}"
                                >
                                  <div class="ten-game-club" [ngClass]="{ 'border-0': card.MemberId == selectedMember }">
                                    <span class="icon icon-check text-primary" *ngIf="card.MemberId == selectedMember"></span>
                                  </div>
                                  <div class="dropdown-link mt-1 text-start">
                                    <div class="font-size-14">{{ card.Name }}</div>
                                    <div class="font-size-14 text-info">
                                      {{ 'member.id.card.text' | translate }} {{ card.MemberId }}
                                    </div>
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <!-- Nav Buttons -->
      <div class="mobile-nav-button-container">
        <a
          class="nav-item fw-bold large-item"
          [routerLink]="['/activity']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="d-flex align-items-center">
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">history</span>
            <div class="nav-text fw-bold font-size-14">{{ 'nav.activity' | translate }}</div>
          </div>
          <div class="icon material-icons material-mobile-dropdown m-0" aria-hidden="true">chevron_right</div>
        </a>

        <a
          class="nav-item"
          [routerLink]="['/rewards/wallet']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="d-flex align-items-center">
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">account_balance_wallet</span>
            <div class="nav-text fw-bold font-size-14">{{ 'nav.wallet' | translate }}</div>
          </div>
          <div class="icon material-icons material-mobile-dropdown m-0" aria-hidden="true">chevron_right</div>
        </a>
        <a
          class="nav-item"
          [routerLink]="['/challenges']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="d-flex align-items-center">
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">flag</span>
            <div class="nav-text fw-bold font-size-14">{{ 'nav.challenges' | translate }}</div>
          </div>
          <div class="icon material-icons material-mobile-dropdown m-0" aria-hidden="true">chevron_right</div>
        </a>
        <a class="nav-item" [routerLink]="['/rewards']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="d-flex align-items-center">
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">redeem</span>
            <div class="nav-text fw-bold font-size-14">{{ 'nav.rewards' | translate }}</div>
          </div>
          <div class="icon material-icons material-mobile-dropdown m-0" aria-hidden="true">chevron_right</div>
        </a>

        <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.games'">
          <a
            class="nav-item"
            [routerLink]="['/games/quiz']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <div class="d-flex align-items-center">
              <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">extension</span>
              <div class="nav-text fw-bold font-size-14">{{ 'nav.games' | translate }}</div>
            </div>
            <div class="icon material-icons material-mobile-dropdown m-0" aria-hidden="true">chevron_right</div>
          </a>
        </ng-container>

        <a class="nav-item" [routerLink]="['/earn']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="d-flex align-items-center">
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">emoji_events</span>
            <div class="nav-text fw-bold font-size-14">{{ 'nav.earn' | translate }}</div>
          </div>
          <div class="icon material-icons material-mobile-dropdown m-0" aria-hidden="true">chevron_right</div>
        </a>

        <a class="nav-item" [routerLink]="['/tiers']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="d-flex align-items-center">
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">bar_chart</span>
            <div class="nav-text fw-bold font-size-14">{{ 'nav.tiers' | translate }}</div>
          </div>
          <div class="icon material-icons material-mobile-dropdown m-0" aria-hidden="true">chevron_right</div>
        </a>

        <a class="nav-item" [routerLink]="['/contact']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="d-flex align-items-center">
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">email</span>
            <div class="nav-text fw-bold font-size-14">{{ 'nav.contact.us' | translate }}</div>
          </div>
          <div class="icon material-icons material-mobile-dropdown m-0" aria-hidden="true">chevron_right</div>
        </a>

        <!-- <a class="nav-item logout-link-div" [routerLink]="['/contact']" (click)="logout()">
          <div class="d-flex align-items-center">
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">power_settings_new</span>
            <div class="nav-text fw-bold font-size-14">{{ 'nav.log.out' | translate }}</div>
          </div>
        </a> -->
      </div>
    </div>
  </div>
</div>
