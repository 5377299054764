<div class="upgrade-benefits-card text-white rounded">
  <div class="upgrade-benefits-card-header mb-3">
    <h5>{{ 'upgrade.benefits.card.title' | translate }}</h5>
  </div>
  <div class="upgrade-benefits-card-body">
    <div
      class="benefits-container benefit-item-{{ i + 1 }}"
      *ngFor="let benefit of items$ | async; let i = index"
      [style.maxWidth]="(isMobile$ | async) ? '100%' : 100 / itemsPerRow + '%'"
      [class.row-last]="(i + 1) % itemsPerRow === 0"
    >
      <div class="image-container" [style.height.px]="imageHeight">
        <div class="img-wrapper">
          <img
            [src]="img"
            alt=""
            draggable="false"
            *ngFor="let img of benefit.images; let x = index"
            class="benefit-image image-{{ x + 1 }}"
            [style.top.px]="benefit.position.top * x"
            [style.left.px]="benefit.position.left * x"
          />
        </div>
      </div>
      <h4 class="upgrade-benefits-title">{{ benefit.title }}</h4>
      <p class="upgrade-benefits-description">
        {{ benefit.description }}
      </p>
    </div>
  </div>
  <div class="upgrade-benefits-card-footer">
    <ng-container *fgbWebsiteContentFeatureFlag="'feature.home.upgrade.benefits.card.button'">
      <a class="btn mt-4 bg-primary rounded text-white fw-bold font-size-16" [href]="link" target="_blank">{{
        'upgrade.benefits.card.button' | translate
      }}</a>
    </ng-container>
  </div>
</div>
