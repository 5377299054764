import { DateTime } from 'luxon';

export class Season {
  /** start month of the season */
  static readonly startMonth = 9; //September

  /** Length of the season in months */
  static readonly tenure = 12;

  /** Start year of the season */
  static get startYear(): number {
    const currentDate = DateTime.now();
    const startedLastYear = currentDate.month < this.startMonth;
    return startedLastYear ? currentDate.year - 1 : currentDate.year;
  }

  /** Luxon date time of the start date */
  static readonly startDateTime = DateTime.fromObject({
    year: this.startYear,
    month: this.startMonth,
  });

  /**
   * Gets the month using the season start year and handles overflow
   *
   * E.g. Season start year of 2024 with 13 monthIndex will return Jan 2025
   */
  static getMonthByIndex(monthIndex: number) {
    return DateTime.fromJSDate(new Date(Season.startYear, monthIndex - 1));
  }
}
