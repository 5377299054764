import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BalanceQuery } from '@fgb/core';
import { config } from '@fgb/core';

@Component({
  selector: 'fgb-points-summary',
  templateUrl: './points-summary.component.html',
  styleUrls: ['./points-summary.component.scss'],
})
export class PointsSummaryComponent implements OnInit {
  pointsBalance$: Observable<number | undefined>;

  constructor(private balanceQuery: BalanceQuery) {}

  ngOnInit() {
    this.pointsBalance$ = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty);
  }
}
