import { Component, OnInit } from '@angular/core';
import { MarketplaceService } from '@fgb/core';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'fgb-events-page',
  templateUrl: './events-page.component.html',
  styleUrls: ['./events-page.component.scss'],
})
export class EventsPageComponent implements OnInit {
  constructor(private marketplaceService: MarketplaceService) {}

  ngOnInit() {
    firstValueFrom(this.marketplaceService.fetchMarketplaceItems());
  }
}
