import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fgb-contact-us-page',
  templateUrl: './contact-us-page.component.html',
  styleUrls: ['./contact-us-page.component.scss'],
})
export class ContactUsPageComponent implements OnInit {
  ngOnInit() {}
}
