<ng-container *ngIf="mixedSurveys$ | async as mixedSurveyItems">
  <div *ngIf="mixedSurveyItems.length > 0">
    <div class="featured-games-header d-flex">
      <h5 class="text-start text-uppercase">{{ 'home.page.featured.games.title' | translate }}</h5>
      <a class="ms-auto link-btn d-flex align-items-center text-black" [routerLink]="'/games/quiz'">
        <span class="link-title font-size-14">{{ 'featured.surveys.view.all' | translate }}</span>
        <i class="material-icons font-size-16 ms-1">arrow_forward_ios</i>
      </a>
    </div>
    <owl-carousel-o *ifScreenSize="screenType.Desktop" [options]="customOptions" (dragging)="isDragging = $event.dragging">
      <ng-container *ngFor="let item of mixedSurveyItems; trackBy: trackByFn">
        <ng-template carouselSlide [width]="930">
          <fgb-survey-item [survey]="item"></fgb-survey-item>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
    <owl-carousel-o *ifScreenSize="screenType.Mobile" [options]="customOptions2" (dragging)="isDragging = $event.dragging">
      <ng-container *ngFor="let item of mixedSurveyItems; trackBy: trackByFn">
        <ng-template carouselSlide [width]="395">
          <fgb-survey-item [survey]="item"></fgb-survey-item>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</ng-container>
