<div class="banner" role="banner">
  <h1>{{ 'terms.page.title' | translate }}</h1>
</div>

<div class="container">
  <div class="row">
    <div class="col-12">
      <fgbcl-terms-and-conditions [type]="termsAndConditionsType" [defaultTerms]="defaultTemplate"></fgbcl-terms-and-conditions>
    </div>
  </div>
</div>

<ng-template #defaultTemplate>
  <p class="terms-section-content text-start mb-3">{{ 'terms.page.loading.text' | translate }}</p>
</ng-template>
