import { Component, OnInit } from '@angular/core';
import { MarketplaceService } from '@fgb/core';

@Component({
  selector: 'fgb-products-page',
  templateUrl: './products-page.component.html',
  styleUrls: ['./products-page.component.scss'],
})
export class ProductsPageComponent implements OnInit {
  constructor(private marketplaceService: MarketplaceService) {}

  ngOnInit(): void {
    this.marketplaceService.fetchMarketplaceItems().toPromise();
  }
}
