import { Component, OnInit } from '@angular/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';

@Component({
  selector: 'fgb-lottos-page',
  templateUrl: './lottos-page.component.html',
  styleUrls: ['./lottos-page.component.scss'],
})
export class LottosPageComponent implements OnInit {
  screenType = ScreenType;

  constructor() {}

  ngOnInit() {}
}
