<div class="banner d-none d-lg-flex" role="banner">
  <h1 class="text-uppercase">{{ 'home.page.banner.title' | translate : { name: (member$ | async)?.FirstName } }}</h1>
</div>

<div class="container tier-container">
  <fgb-tier-v2></fgb-tier-v2>
</div>

<ng-container *inPopulation="[populations.NonMember]">
  <div class="container">
    <fgb-member-upgrade-card></fgb-member-upgrade-card>
  </div>
</ng-container>
<ng-container *inPopulation="[populations.NonMember]">
  <ng-container *ngTemplateOutlet="scorecard"></ng-container>
</ng-container>

<ng-container *inPopulation="[populations.NonMember]">
  <div class="container">
    <!-- Featured Rewards -->
    <div class="rewards-container">
      <fgbcl-featured-rewards [showAllPath]="'/rewards'"></fgbcl-featured-rewards>
    </div>
    <div class="upgrade-benefits-container mb-lg-0 mb-2">
      <fgb-upgrade-benefits-card></fgb-upgrade-benefits-card>
    </div>
  </div>
</ng-container>

<ng-container *notInPopulation="[populations.NonMember]">
  <!--Scorecard carousel-->
  <div class="mt-1 scorecard-container">
    <ng-container *ngTemplateOutlet="scorecard"></ng-container>
  </div>
  <div class="container voucher-container">
    <!-- vouchers -->
    <fgb-vouchers></fgb-vouchers>
  </div>
</ng-container>

<div class="container d-flex flex-column">
  <ng-container *notInPopulation="[populations.NonMember]">
    <!-- Featured Rewards -->
    <div class="rewards-container">
      <fgbcl-featured-rewards [showAllPath]="'/rewards'"></fgbcl-featured-rewards>
    </div>

    <!--Featured Games-->
    <div class="featured-games-container">
      <fgb-featured-surveys></fgb-featured-surveys>
    </div>
  </ng-container>
  <!-- Announcements -->
  <div class="announcement-container">
    <fgbcl-announcements></fgbcl-announcements>
  </div>
</div>

<ng-template #scorecard>
  <fgbcl-scorecard-carousel [icon]="'chevron_right'" [showAllView]="true" [phaseThree]="true"></fgbcl-scorecard-carousel>
</ng-template>
